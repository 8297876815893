<dialog id="Contact" class="modal p-0 !bg-transparent">
	<div class="modal-box absolute z-5 flex p-0 w-fit max-h-fit lg:max-w-[900px] max-w-screen">
		<iframe
			title="LANDS Map"
			src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5542.679862246583!2d8.951698!3d46.004407!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478432725ebcca4f%3A0xd5ce784402d2a08!2sLANDS%20Sagl!5e0!3m2!1sit!2sch!4v1717749867089!5m2!1sit!2sch"
			width="800"
			height="600"
			style="border:0;"
			allowfullscreen=""
			loading="lazy"
			referrerpolicy="no-referrer-when-downgrade"
		/>
		<form method="dialog">
			<button
				class="btn btn-sm btn-circle btn-ghost absolute right-4 top-4 text-red-700 text-2xl [text-shadow:_0_0_5px_rgb(0_0_0)]"
				>✕</button
			>
		</form>
	</div>
	<form method="dialog" class="modal-backdrop">
		<button class="w-screen h-screen">✕</button>
	</form>
</dialog>
